<template>
        <el-dialog title="Calcul sporuri" :visible.sync="showPopup" class="my-dialog-class" >
        <el-form label-position="top" :inline="false" :rules="rules" label-width="100%"  :model='selectedObject'  ref='my-form' @submit.prevent="save" v-loading="loadingVisible" >
            <el-row :gutter="15" >
                
                <el-col :span='8'>
                    <el-form-item label='Minim ore' >
                        <el-input-number class='full-width' v-model='selectedObject.Min' ></el-input-number>
                    </el-form-item>
                </el-col> 
                <el-col :span='8'>
                    <el-form-item label='Maxim ore' >
                        <el-input-number class='full-width' v-model='selectedObject.Max' ></el-input-number>
                    </el-form-item>
                </el-col>    
                <el-col :span='8'>
                    <el-form-item label='Spor %' >
                        <el-input-number class='full-width' v-model='selectedObject.Spor' ></el-input-number>
                    </el-form-item>
                </el-col>      
                
            </el-row>
        </el-form>

        <span slot="footer" class="dialog-footer" >
            <el-button @click="showPopup=false"     > Renunta  </el-button>
            <el-button type="primary" @click="save" > Salveaza </el-button>
        </span>
    </el-dialog>
</template>

<script>
    import settings from "@/backend/LocalSettings";
    import BasePage from '@/pages/BasePage';

    export default {
        name: "Sporuri_dialog",
        extends: BasePage,
        data () {
            return {
                baseUrl :'',
                showPopup: false,
                mode: 'add',
                selectedObjectTemplate: {Min: '' , Max:'', Spor:''},    
                selectedObject:{},              
                Info:{ },
                                   
                rules: {
                    Min: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Max: [ { required: true, message: 'Campul este obligatoriu' } ], 
                    Spor: [ { required: true, message: 'Campul este obligatoriu' } ], 
                }
            }
        },
        methods: {
            show_me: async function( id ) {
                this.selectedObject = JSON.parse(JSON.stringify(this.selectedObjectTemplate));
                this.showPopup        = true;
                if( id == null )
                {
                    this.mode = "add";
                }
                else
                {
                    this.mode             = "edit";
                    var            result = await this.post("calcul_sporuri/get_info_item_dialog", { id: id } );
                    this.selectedObject   = result.Item;
                }
            },
            async get_info(){
                var response = await this.post("calcul_sporuri/get_info_for_dialog" );
            },
            save: async function() {
                // console.log(this.selectedObject);
                // return
                this.$refs['my-form'].validate( async(valid) => {
                    if (valid)
                    {
                        await this.post("calcul_sporuri/save", { mode: this.mode, object: this.selectedObject } );
                        this.showPopup = false;
                        this.$emit("save");
                    }
                });
            }
        },
        mounted: function() {
            this.baseUrl = settings.BASE_URL;
            this.get_info();
        }
    };
</script>

<style lang="less" scoped>
    .full-width {
        width: 100%;
    }

</style>